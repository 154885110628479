import React from 'react'
import Markdown from '@frontastic/catwalk/src/js/component/markdown'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const MarkdownTastic = ({ data }) => {
    const { bgColor, textColor, media } = data
    const style = {}
    if (bgColor) {
        style['backgroundColor'] = bgColor
    }
    if (media && media.media) {
        style['backgroundImage'] = `url(${media && media.media && media.media.file})`
    }
    if (textColor) {
        style['color'] = textColor
    }
    return (
        <div style={style} className='bg-center bg-cover bg-no-repeat'>
            <Markdown
                text={data.text}
                className={classnames(
                    'markdown--align__' + data.align,
                    'markdown--padding__' + data.padding
                )}
            />
        </div>
    )
}

MarkdownTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify()(MarkdownTastic);
